<template>
     <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                  <div class="kt-list p-0">
                    <div class="kt-list__item p-0">
                        <div class="col-md-3 my-2">
                            <div class="pointer btn-list-caller">
                                <label class="btn-list-caller-content btn btn-default btn-kotak">
                                    <div class="icon_antrian">
                                        <i :class="getType('MANUAL')" style="font-size: 0.75rem"></i>
                                        <i :class="getClass('CHECKEDIN')" style="font-size: 0.75rem"></i>
                                    </div>
                                    <input type="radio" class="d-none">
                                    <div class="btn-kotak-content">
                                        00xxx
                                    </div>
                                </label>
                            </div>
                        </div>
                      <div class="col-md-9">
                        <span>Antrian dengan No. 00xxx belum dipanggil/dilayani.</span>
                      </div>
                    </div>
                    <div class="kt-list__item p-0">
                        <div class="col-md-3 my-2">
                            <div class="pointer btn-list-caller">
                                <label class="btn-list-caller-content btn btn-default btn-kotak">
                                    <div class="icon_antrian">
                                        <i :class="getType('AUTO')" style="font-size: 0.75rem"></i>
                                        <i :class="getClass('CHECKEDIN')" style="font-size: 0.75rem"></i>
                                    </div>
                                    <input type="radio" class="d-none">
                                    <div class="btn-kotak-content">
                                        00xxx
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <span>Antrian dengan No. 00xxx terdaftar di Mobile dan belum dipanggil/dilayani.</span>
                        </div>
                    </div>
                    <div class="kt-list__item p-0">
                        <div class="col-md-3 my-2">
                            <div class="pointer btn-list-caller">
                                <label class="btn-list-caller-content btn btn-default btn-kotak">
                                    <div class="icon_antrian">
                                        <i :class="getType('MANUAL')" style="font-size: 0.75rem"></i>
                                        <i :class="getClass('CALLING')" style="font-size: 0.75rem"></i>
                                    </div>
                                    <input type="radio" class="d-none">
                                    <div class="btn-kotak-content">
                                        00xxx
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <span>Antrian dengan No. 00xxx sudah dipanggil dan belum dilayani.</span>
                        </div>
                    </div>
                    <div class="kt-list__item p-0">
                        <div class="col-md-3 my-2">
                            <div class="pointer btn-list-caller">
                                <label class="btn-list-caller-content btn btn-default btn-kotak">
                                    <div class="icon_antrian">
                                        <i :class="getType('MANUAL')" style="font-size: 0.75rem"></i>
                                        <i :class="getClass('SERVING')" style="font-size: 0.75rem"></i>
                                    </div>
                                    <input type="radio" class="d-none">
                                    <div class="btn-kotak-content">
                                        00xxx
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <span>Antrian dengan No. 00xxx sudah dipanggil dan sedang dilayani.</span>
                        </div>
                    </div>
                    <div class="kt-list__item p-0">
                        <div class="col-md-3 my-2">
                            <div class="pointer btn-list-caller">
                                <label class="btn-list-caller-content btn btn-default btn-kotak active">
                                    <div class="icon_antrian">
                                        <i :class="getType('AUTO')" style="font-size: 0.75rem"></i>
                                        <i :class="getClass('CALLING')" style="font-size: 0.75rem"></i>
                                    </div>
                                    <input type="radio" class="d-none">
                                    <div class="btn-kotak-content">
                                        00xxx
                                    </div>
                                </label>
                            </div>                        
                        </div>
                        <div class="col-md-9">
                            <span>Antrian dengan No. 00xxx sudah dipanggil, belum dilayani dan sedang dipilih.</span>
                        </div>
                    </div>
                    <div class="kt-list__item p-0 justify-content-between">
                      <div class="col-md-2 text-center h-100 rounded p-2">
                        <span class="btn btn-default btn-circle btn-elevate btn-icon" disabled>
                            <i class="fas fa-arrow-left"></i>
                        </span>
                      </div>
                      <div class="col-md-9">
                        <span>Klik untuk kembali ke menu pilih caller.</span>
                      </div>
                    </div>
                    <div class="kt-list__item p-0 justify-content-between">
                      <div class="col-md-2 text-center h-100 rounded p-2">
                        <span class="btn btn btn-default btn-elevate btn-circle btn-icon mr-2" disabled>
                            <i class="fas fa-print"></i>
                            <i class="fas fa-cog" style="margin-left: 2px"></i>
                        </span>
                      </div>
                      <div class="col-md-9">
                        <span>Klik untuk memilih printer.</span>
                      </div>
                    </div>
                    <div class="kt-list__item p-0 justify-content-between">
                      <div class="col-md-2 text-center h-100 rounded p-2">
                        <span class="btn btn btn-default btn-elevate btn-circle btn-icon mr-2" disabled>
                            <i class="fas fa-history"></i>
                        </span>
                      </div>
                      <div class="col-md-9">
                        <span>Klik untuk melihat riwayat antrian.</span>
                      </div>
                    </div>
                </div>
                </div>
                <div class="modal-footer row justify-content-center m-0">
                      <button type="submit" class="btn btn-primary" @click="close()">{{ $t('button.close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
    };
  },
  props: {
    modalName: { type: String, default:"modalHelp"},
  },
  methods: {
    ...mapMutations({
        setShowModalHelp: "UnitCallerStore/SET_SHOW_MODAL_HELP"
    }),
    close() {
        $("#" + this.modalName).modal("hide");
        this.setShowModalHelp(false);
    },
    getClass(queueStatus){
        return helpers.setIconCaller(queueStatus)
    },
    getType(queueType){
        switch(queueType){
            case 'AUTO' :
                return "fas fa-mobile-alt"
            default:
                return ""
        }
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
  }
};
</script>
