<template>
     <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                  <div class="kt-list">
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-checkedin" style="font-size: 0.8rem">
                        <span>00xxx</span> <br>
                        <span>HH:mm - HH:mm</span>
                        <i class="fas fa-cloud" style="position:absolute;top:10px;right: 10px;"></i>
                      </div>
                      <div class="col-md-8">
                        <span>Antrian dengan No. 00xxx belum check in.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-checkedin" style="font-size: 0.8rem">
                        <span>00xxx</span> <br>
                        <span>HH:mm - HH:mm</span>
                        <i class="far fa-user" style="position:absolute;top:10px;right: 10px;"></i>
                      </div>
                      <div class="col-md-8">
                        <span>Antrian dengan No. 00xxx sudah check in dan belum dipanggil.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-calling" style="font-size: 0.8rem">
                        <span>00xxx</span> <br>
                        <span>HH:mm - HH:mm</span>
                        <i class="fas fa-bullhorn" style="position:absolute;top:10px;right: 10px;"></i>
                      </div>
                      <div class="col-md-8">
                        <span>Antrian dengan No. 00xxx telah dipanggil tapi belum dilayani.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-serving" style="font-size: 0.8rem">
                        <span>00xxx</span> <br>
                        <span>HH:mm - HH:mm</span>
                        <i class="fas fa-user-clock" style="position:absolute;top:10px;right: 10px;"></i>
                      </div>
                      <div class="col-md-8">
                        <span>Antrian dengan No. 00xxx sedang dilayani.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-done" style="font-size: 0.8rem">
                        <span>00xxx</span> <br>
                        <span>HH:mm - HH:mm</span>
                        <i class="fas fa-user-check" style="position:absolute;top:10px;right: 10px;"></i>
                      </div>
                      <div class="col-md-8">
                        <span>Antrian dengan No. 00xxx telah selesai dilayani.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-skipped" style="font-size: 0.8rem">
                        <span>00xxx</span> <br>
                        <span>HH:mm - HH:mm</span>
                        <i class="fas fa-user-times" style="position:absolute;top:10px;right: 10px;"></i>
                      </div>
                      <div class="col-md-8">
                        <span>Antrian dengan No. 00xxx dilewati.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2  bg-cancel" style="font-size: 0.8rem">
                        <span>00xxx</span> <br>
                        <span>HH:mm - HH:mm</span>
                        <i class="fas fa-ban" style="position:absolute;top:10px;right: 10px;"></i>
                      </div>
                      <div class="col-md-8">
                        <span>Antrian dengan No. 00xxx telah dibatalkan.</span>
                      </div>
                    </div>
                     <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-checkedin" style="font-size: 0.8rem">
                        <span>B00xxx</span> <br>
                        <span> Pasien </span><br>
                        <span> Dokter </span><br>
                        <span>HH:mm - HH:mm</span>
                         <span style="position:absolute;top:10px;right: 10px;">
                            TELEKONSUL &nbsp;<i class="fas fa-headset" ></i>
                        </span> 
                      </div>
                      <div class="col-md-8">
                        <span>Antrian dengan No. 00xxx telah dibatalkan.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2">
                        <span class="btn btn-default btn-circle btn-elevate btn-icon" disabled>
                            <i class="fas fa-arrow-left"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Klik untuk kembali ke menu pilih caller.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2">
                        <span class="btn btn btn-default btn-elevate btn-circle btn-icon mr-2" disabled>
                            <i class="fas fa-print"></i>
                            <i class="fas fa-cog" style="margin-left: 2px"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Klik untuk memilih printer.</span>
                      </div>
                    </div>
                </div>
                </div>
                <div class="modal-footer row justify-content-center m-0">
                      <button type="submit" class="btn btn-primary" @click="close()">{{ $t('button.close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
    };
  },
  props: {
    modalName: { type: String, default:"modalHelp"},
  },
  methods: {
    ...mapMutations({
        setShowModalHelp: "DoctorCallerStore/SET_SHOW_MODAL_HELP"
    }),
    close() {
        $("#" + this.modalName).modal("hide");
        this.setShowModalHelp(false);
    },
  },
  mounted() {
    $("#" + this.modalName).modal();
  }
};
</script>
