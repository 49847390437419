<template>
     <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Note</h5>
                <button type="button" class="close" data-dismiss="modal" @click="close"></button>
              </div>
                <div class="modal-body">
                  <div class="row ">
                      <div class="col-12">
                        notes
                          <textarea class="form-control" rows="3"  v-model="selectedQueue.note"></textarea>
                          <!-- <textarea class="form-control" rows="3" v-else></textarea> -->
                      </div>
                  </div>
                </div>
                <div class="modal-footer row justify-content-center m-0">
                      <button type="submit" class="btn btn-primary" @click="close()">{{ $t('button.close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import UnitCallerStore from '../../../../store/UnitCallerStore';

export default {
  data() {
    return {
    };
  },
  props: {
    modalName: { type: String, default:"modalNote"},
  },
  computed:{
    ...mapState('UnitCallerStore', {
      selectedQueue: state => state.selectedQueue,
    })
  },
  methods: {
    ...mapMutations({
        setShowModalNote: "UnitCallerStore/SET_SHOW_MODAL_NOTE",
    }),
    updateValue: function (value) {
      this.$emit('input', value);
    },
    close() {
        $("#" + this.modalName).modal("hide");
        this.setShowModalNote(false);
    },
  },
  mounted() {
    $("#" + this.modalName).modal();
  }
};
</script>
