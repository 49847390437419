<template>
     <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                  <div class="kt-list">
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-internal-event">
                        <span>HH:mm - HH:mm</span>
                      </div>
                      <div class="col-md-8">
                        <span>{{ $t('formInternal.title') }}. Klik untuk mengubah jam event.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-telat">
                        <span>HH:mm - HH:mm</span>
                      </div>
                      <div class="col-md-8">
                        <span>Slot jadwal dengan keterangan telat. Klik untuk mengubah jam event.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-reserved">
                        <span>HH:mm - HH:mm</span>
                      </div>
                      <div class="col-md-8">
                      <span>Slot jadwal telah terisi reservasi. Klik untuk melihat detail reservasi atau batalkan reservasi.</span>
                      </div>
                    </div>
                     <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-reserved">
                        <span>HH:mm - HH:mm
                          <i class="fas fa-mobile-alt float-right"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Reservasi dibuat melalui mobile</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-reserved">
                        <span>HH:mm - HH:mm
                          <i class="fas fa-headset pointer float-right"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Reservasi untuk telekonsul</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-empty border border-dark">
                        <span>HH:mm - HH:mm
                          <i class="fa fa-plus float-right"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Slot jadwal kosong, klik untuk menambahkan reservasi</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2">
                        <span class="btn btn-default btn-circle btn-elevate btn-icon">
                            <i class="fas fa-plus"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Klik untuk menambahkan reservasi baru.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2">
                        <span class="btn btn-default btn-bold btn-upper btn-font-sm">
                            <i class="la  la-volume-up"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Sound Notification On</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2">
                        <span class="btn btn-default btn-bold btn-upper btn-font-sm">
                            <i class="la  la-volume-off"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Sound Notification off</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2">
                        <span class="btn btn-default btn-circle btn-elevate btn-icon">
                            <i class="far fa-calendar-plus"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Klik untuk menambahkan Internal Event.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2">
                        <span class="btn btn-default btn-circle btn-elevate btn-icon">
                            <i class="fas fa-print"></i>
                            <i class="fas fa-cog" style="margin-left: 2px"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Klik untuk Config Printer.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2">
                        <span class="btn btn-default btn-circle btn-elevate btn-icon">
                            <i class="fas fa-history"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Klik untuk Menampilkan History.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2">
                        <span class="btn btn-default btn-circle btn-elevate btn-icon">
                            <i class="fas fa-expand"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Klik untuk Menampilkan fullscreen.</span>
                      </div>
                    </div>
                    <!-- <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2">
                        <span class="btn btn-default btn-circle btn-elevate btn-icon">
                            <i style="color:red;" class="fas fa-ban"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Klik untuk memblock jadwal.</span>
                      </div>
                    </div> -->
                    
                </div>
                </div>
                <div class="modal-footer row justify-content-center m-0">
                      <button type="submit" class="btn btn-primary" @click="close()">{{ $t('button.close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
    };
  },
  props: {
    modalName: { type: String, default:"modalHelp"},
  },
  methods: {
    ...mapMutations({
        setShowModalHelp: "DayCalendarStore/SET_SHOW_MODAL_HELP"
    }),
    close() {
        $("#" + this.modalName).modal("hide");
        this.setShowModalHelp(false);
    },
  },
  mounted() {
    $("#" + this.modalName).modal();
  }
};
</script>
