var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal",attrs:{"id":_vm.modalName,"role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"kt-list"},[_c('div',{staticClass:"kt-list__item"},[_vm._m(0),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v(_vm._s(_vm.$t('formInternal.title'))+". Klik untuk mengubah jam event.")])])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)])]),_c('div',{staticClass:"modal-footer row justify-content-center m-0"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('button.close')))])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 h-100 rounded p-2 bg-internal-event"},[_c('span',[_vm._v("HH:mm - HH:mm")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-list__item"},[_c('div',{staticClass:"col-md-4 h-100 rounded p-2 bg-telat"},[_c('span',[_vm._v("HH:mm - HH:mm")])]),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v("Slot jadwal dengan keterangan telat. Klik untuk mengubah jam event.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-list__item"},[_c('div',{staticClass:"col-md-4 h-100 rounded p-2 bg-reserved"},[_c('span',[_vm._v("HH:mm - HH:mm")])]),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v("Slot jadwal telah terisi reservasi. Klik untuk melihat detail reservasi atau batalkan reservasi.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-list__item"},[_c('div',{staticClass:"col-md-4 h-100 rounded p-2 bg-reserved"},[_c('span',[_vm._v("HH:mm - HH:mm "),_c('i',{staticClass:"fas fa-mobile-alt float-right"})])]),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v("Reservasi dibuat melalui mobile")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-list__item"},[_c('div',{staticClass:"col-md-4 h-100 rounded p-2 bg-reserved"},[_c('span',[_vm._v("HH:mm - HH:mm "),_c('i',{staticClass:"fas fa-headset pointer float-right"})])]),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v("Reservasi untuk telekonsul")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-list__item"},[_c('div',{staticClass:"col-md-4 h-100 rounded p-2 bg-empty border border-dark"},[_c('span',[_vm._v("HH:mm - HH:mm "),_c('i',{staticClass:"fa fa-plus float-right"})])]),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v("Slot jadwal kosong, klik untuk menambahkan reservasi")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-list__item"},[_c('div',{staticClass:"col-md-4 h-100 rounded p-2"},[_c('span',{staticClass:"btn btn-default btn-circle btn-elevate btn-icon"},[_c('i',{staticClass:"fas fa-plus"})])]),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v("Klik untuk menambahkan reservasi baru.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-list__item"},[_c('div',{staticClass:"col-md-4 h-100 rounded p-2"},[_c('span',{staticClass:"btn btn-default btn-bold btn-upper btn-font-sm"},[_c('i',{staticClass:"la la-volume-up"})])]),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v("Sound Notification On")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-list__item"},[_c('div',{staticClass:"col-md-4 h-100 rounded p-2"},[_c('span',{staticClass:"btn btn-default btn-bold btn-upper btn-font-sm"},[_c('i',{staticClass:"la la-volume-off"})])]),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v("Sound Notification off")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-list__item"},[_c('div',{staticClass:"col-md-4 h-100 rounded p-2"},[_c('span',{staticClass:"btn btn-default btn-circle btn-elevate btn-icon"},[_c('i',{staticClass:"far fa-calendar-plus"})])]),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v("Klik untuk menambahkan Internal Event.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-list__item"},[_c('div',{staticClass:"col-md-4 h-100 rounded p-2"},[_c('span',{staticClass:"btn btn-default btn-circle btn-elevate btn-icon"},[_c('i',{staticClass:"fas fa-print"}),_c('i',{staticClass:"fas fa-cog",staticStyle:{"margin-left":"2px"}})])]),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v("Klik untuk Config Printer.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-list__item"},[_c('div',{staticClass:"col-md-4 h-100 rounded p-2"},[_c('span',{staticClass:"btn btn-default btn-circle btn-elevate btn-icon"},[_c('i',{staticClass:"fas fa-history"})])]),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v("Klik untuk Menampilkan History.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-list__item"},[_c('div',{staticClass:"col-md-4 h-100 rounded p-2"},[_c('span',{staticClass:"btn btn-default btn-circle btn-elevate btn-icon"},[_c('i',{staticClass:"fas fa-expand"})])]),_c('div',{staticClass:"col-md-8"},[_c('span',[_vm._v("Klik untuk Menampilkan fullscreen.")])])])
}]

export { render, staticRenderFns }